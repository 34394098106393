/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';


html body{
    //font-family: 'Ubuntu', sans-serif;
    font-family: 'Barlow', sans-serif;
    /* Created with https://www.css-gradient.com */
    font-size: 0.95em !important;
}

body{
    background: #BBE4FF;
background: -webkit-linear-gradient(top, #BBE4FF, #FFFFFF);
background: -moz-linear-gradient(top, #BBE4FF, #FFFFFF);
background: linear-gradient(to bottom, #BBE4FF, #FFFFFF);
background-repeat: no-repeat;
}

.form-control{
    
    font-size: 1em;
}

.input-group .input-group-text{
    font-size: 0.9em;
}


.input-group .form-control{
    padding: 0.2rem 0.3rem !important;
    font-size: 0.9em;
}

.input-group .form-select{
    padding: 0.2rem 0.3rem !important;
    font-size: 0.9em;
}

.form-select{
    font-size: 0.9em;
}

.form-select option{
    font-size: 0.9em;
}

.btn{
    font-size: 0.9em;
}

.table{
    font-size: 0.9em;
}

.card{
    //box-shadow: 1px 2px 5px 3px rgba(230,230,230,0.5);
    //-webkit-box-shadow: 1px 2px 5px 3px rgba(230,230,230,0.5);
    //-moz-box-shadow: 1px 2px 5px 3px rgba(230,230,230,0.5);
    //box-shadow: 0 3px 6px rgba(0, 0, 0, 0.093), 0 3px 6px rgba(0, 0, 0, 0.141);
    //box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    box-shadow: 1px 1px 9px 0px rgba(0,0,0,0.15);
-webkit-box-shadow: 1px 1px 9px 0px rgba(0,0,0,0.15);
-moz-box-shadow: 1px 1px 9px 0px rgba(0,0,0,0.15);
}

.lbl{
    display: block;
    font-size:0.85em;
    color: rgb(123, 123, 123);
}

.input-upper{
    text-transform: uppercase;
}

.card-table-container{
    min-width: 250px;
    overflow-x: auto;
}

.par-row{
    background-color: rgba(0, 0, 0, 0.075);
}

.fixed-manual-image{
    width: 100% !important;
    padding-bottom: 1em;
}


.spinner-load {
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: #F99E1C;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    /* transform: rotate(3deg); */
     /* transform: rotate(0.3rad);/ */
     /* transform: rotate(3grad); */ 
     /* transform: rotate(.03turn);  */
  }
  
  @keyframes spin {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
  }

  .swal-button {
    background-color: #0d6efd !important;
  }

  .swal-button--cancel{
    background-color: #eee !important;
    color: #333 !important;
  }